import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const Opportunity = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulOpportunityPage {
      opportunityList {
        title
        text {
          text
        }
        icon
        readMoreLinks {
          linkTitle
          link
        }
      }
    }
  }
`)
  const list = data.contentfulOpportunityPage.opportunityList;
  return (
    <div className="row g-0">
      {list.map(({ title, text, icon, readMoreLinks }, index) => (
        <div key={index} className="service-block col-lg-6 col-md-6 col-sm-12">
          <div className="inner-box">
            <div className="icon-box">
              <span className={icon}></span>
            </div>
            <h5>{title}</h5>
            <div className="text">{text.text}</div>
            {readMoreLinks.map(({ linkTitle, link }, index) => (
              <div key={index}>
                <a className="read-more" target="_blank" rel="noreferrer" href={link}>{linkTitle}</a> <br />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Opportunity;
