import * as React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Opportunity from '../components/opportunity/opportunity';

const Resources = () => (
  <Layout>
    <SEO title="Resources" />
    <div className="sidebar-page-container">
      <div className="services-section">
        <div className="auto-container">
          <div className="sec-title">
            <div className="title">tekki fii</div>
            <h2><span>Opportunities</span> To Explore</h2>
          </div>
          <div className="inner-container">
            <Opportunity />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
export default Resources
